.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #383d48;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gameLink {
  text-decoration: none;
}

.backToLobbyLink {
  position: absolute;
  top: 0px;
  right: 0px;
  text-decoration: none;
  text-align: center;
  width: 25px;
  height: 25px;
  font-size: 16px;
  font-weight: bold;
  border: solid 1px rgb(97, 8, 8);
  color: white;
  background-color: red;
}

.gameTitle {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  color: white;
  font-size: 40px;
  font-weight: bolder;
  padding: 22px;
  backdrop-filter: blur(8px);
  flex-grow: 1;
  text-align: center;
  vertical-align: middle;
}

.JackpotTicker {
  width: 200px;
  border: solid 1px black;
  border-radius: 10px;
  background-color: white;
  color: black;
  font-size: 12px;
  text-align: "center"
}